import React, { FC } from 'react';
import { Toast as ToastComponent, ToastSkin, ToastPlacement } from 'wix-ui-tpa';

import { st, classes } from './Toast.st.css';

interface ToastProps {
  isMobile?: boolean;
  message: string;
  skin?: ToastSkin;
  handleClose?: () => void;
}

const Toast: FC<ToastProps> = ({ isMobile, message, skin, handleClose }) => {
  return (
    <div className={!isMobile ? st(classes.toastContainer) : undefined}>
      <ToastComponent
        className={st(classes.toast)}
        onClose={handleClose}
        skin={skin || ToastSkin.success}
        shouldShowCloseButton={!!handleClose}
        placement={
          isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline
        }
      >
        {message}
      </ToastComponent>
    </div>
  );
};

export default Toast;
