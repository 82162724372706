import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Button, ButtonPriority } from 'wix-ui-tpa';

import { st, classes } from './ConfirmDeleteModal.st.css';

interface ConfirmDeleteModalProps {
  title: string;
  subTitle: string;
  handleConfirm: () => void;
  handleCloseModal: () => void;
}

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({
  title,
  subTitle,
  handleConfirm,
  handleCloseModal,
}) => {
  const { t } = useTranslation();

  return (
    <div className={st(classes.modalContentContainer)}>
      <h2 className={st(classes.modalTitle)}>{title}</h2>
      <div className={st(classes.modalSubTitle)}>{subTitle}</div>

      <div className={st(classes.buttonsContainer)}>
        <div>
          <Button
            onClick={handleCloseModal}
            priority={ButtonPriority.basicSecondary}
          >
            {t('delete-confirmation.cancel')}
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              handleConfirm();
              handleCloseModal();
            }}
          >
            {t('delete-confirmation.delete')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
