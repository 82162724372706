import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Pagination as PaginationComponent } from 'wix-ui-tpa';

import { PaginationConfig } from '@common/store/member-slice';
import { FilterParams } from '@common/services';
import { getActivePage } from './Pagination.utils';
import { classes, st } from './Pagination.st.css';

interface PaginationProps {
  isMobile?: boolean;
  isLoading?: boolean;
  config: PaginationConfig;
  handlePageChange: (filter: FilterParams) => void;
  handleOnBeforePageChange?: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  isMobile = false,
  isLoading,
  config,
  handlePageChange,
  handleOnBeforePageChange,
}) => {
  const [filter, setFilter] = useState<FilterParams | undefined>();
  const [newFilter, setNewFilter] = useState<FilterParams | undefined>();

  useEffect(() => {
    if (!isLoading && !!newFilter && !isEqual(newFilter, filter)) {
      setFilter(newFilter);
      handlePageChange(newFilter);
    }
  }, [isLoading, handlePageChange, filter, newFilter]);

  return (
    <div className={st(classes.paginationContainer, { mobile: isMobile })}>
      <PaginationComponent
        disabled={isLoading}
        currentPage={getActivePage(config)}
        totalPages={config.pageCount}
        onChange={({ page }) => {
          const newOffset = (page - 1) * config.pageSize;

          handleOnBeforePageChange && handleOnBeforePageChange(page);
          setNewFilter({ offset: newOffset });
        }}
      />
    </div>
  );
};

export default Pagination;
