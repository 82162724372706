import { initI18n as initI18nICU } from '@wix/yoshi-flow-editor';

const icuOptions = {
  formats: {
    date: {
      wcFullDate: { month: 'short', day: '2-digit', year: 'numeric' },
    },
  },
};

export const getI18nConfigICU = (
  language: string,
  translations: { [key: string]: string },
) => {
  return initI18nICU({
    locale: language,
    messages: translations,
    icuOptions,
  });
};
