import React, { FC, useState } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { ToastSkin } from 'wix-ui-tpa';

import { DecoratedPost } from '@api/member.models';
import {
  Status,
  getMemberPosts,
} from '@common/store/member-slice/member-posts-slice';
import { useBiLogger } from '@common/providers';
import { useModal, useToast } from '@common/hooks';
import { scrollToTarget, copyTextToClipboard } from '@common/utils';
import {
  WidgetContainer,
  Pagination,
  ThreeDotsLoader,
  ConfirmDeleteModal,
  ErrorMessage,
} from '@common/components';
import { ControllerProps } from '../../controller';
import { PostList } from '../containers';

const POSTS_WIDGET_CONTAINER_DATA_HOOK = 'posts-widget-container';

const MemberPosts: FC<ControllerProps> = ({ state, actions }) => {
  const { t } = useTranslation();
  const { logEvent } = useBiLogger();
  const { isRTL, isMobile } = useEnvironment();
  const [activePost, setActivePost] = useState<DecoratedPost | undefined>();

  const { showToast, Toast } = useToast();
  // @TODO maybe modals could be moved to list container?
  const { showModal: showConfirmDeleteModal, Modal: ConfirmDeletePostModal } =
    useModal(ConfirmDeleteModal, {
      onClose: () => {
        // @TODO maybe add BI events here and onOpen
        setActivePost(undefined);
      },
    });

  const pageTitle = t('profile-forum-posts.forum-posts');
  const memberPosts = getMemberPosts(state);

  switch (memberPosts.status) {
    case Status.INITIAL:
      return (
        <WidgetContainer
          dataHook={POSTS_WIDGET_CONTAINER_DATA_HOOK}
          isRTL={isRTL}
          isMobile={isMobile}
          title={pageTitle}
        >
          <ThreeDotsLoader />
        </WidgetContainer>
      );

    case Status.READY:
    case Status.PENDING:
    case Status.PENDING_AFTER_DELETE:
      const { status, posts, pagination } = memberPosts;
      const isLoading =
        status === Status.PENDING || status === Status.PENDING_AFTER_DELETE;

      return (
        <WidgetContainer
          dataHook={POSTS_WIDGET_CONTAINER_DATA_HOOK}
          isRTL={isRTL}
          isMobile={isMobile}
          title={pageTitle}
        >
          <Toast />
          <ConfirmDeletePostModal
            title={t('delete-post-modal.delete-post')}
            subTitle={t('delete-post-modal.will-detele-comments')}
            handleConfirm={async () => {
              if (activePost) {
                const isLastItemOnPage =
                  posts.length === 1 && posts[0].id === activePost.id;

                await actions.deleteMemberPost(
                  activePost?.id,
                  isLastItemOnPage,
                );

                logEvent({
                  evid: 555,
                  type: 'post',
                  post_id: activePost?.id,
                });
              }
            }}
          />

          <PostList
            isRTL={isRTL}
            isMobile={isMobile}
            isLoading={isLoading}
            posts={posts}
            handleDeleteItem={(postData: DecoratedPost) => {
              setActivePost(postData);
              showConfirmDeleteModal();
            }}
            handleCopyItemLink={async (postUrl: string) => {
              if (postUrl) {
                if (await copyTextToClipboard(postUrl)) {
                  showToast({ message: t('share-link.link-copied'), isMobile });
                } else {
                  showToast({
                    message: t('share-link.link-copied-failure'),
                    isMobile,
                    skin: ToastSkin.error,
                  });
                }
              }
            }}
            handleOpenItem={(postUrl: string) => {
              if (postUrl) {
                actions.navigateTo(postUrl);
              }
            }}
          />

          {!isLoading && pagination.pageCount > 1 && (
            <Pagination
              isMobile={isMobile}
              config={pagination}
              handlePageChange={actions.fetchMemberPosts}
              handleOnBeforePageChange={(nextPage) => {
                scrollToTarget(POSTS_WIDGET_CONTAINER_DATA_HOOK);
                logEvent({
                  evid: 519,
                  page_destination: String(nextPage),
                });
              }}
            />
          )}
        </WidgetContainer>
      );

    case Status.ERROR:
      return <ErrorMessage />;

    default:
      throw new Error('Unreachable');
  }
};

export default MemberPosts;
