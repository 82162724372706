import { createAsyncThunk } from '@reduxjs/toolkit';

import { ServicesMap, FilterParams } from '@common/services';
import { Status } from '../member-slice.models';
import { MemberPostsSlice, MemberPostsData } from './member-posts-slice.models';
import { PartialState, getMemberPosts } from './member-posts-slice.selectors';

export type ThunkConfig = {
  extra: {
    services: ServicesMap;
  };
};

interface InitialFetchParams {
  userId: string;
  filter?: FilterParams;
}

export const fetchInitialMemberPosts = createAsyncThunk<
  MemberPostsSlice,
  InitialFetchParams,
  ThunkConfig
>(
  'memberPosts/fetchInitialMemberPosts',
  (
    { userId, filter },
    { getState, extra: { services } },
  ): Promise<MemberPostsSlice> => {
    const state = getState() as PartialState;
    const memberPosts = getMemberPosts(state);

    if (memberPosts.status === Status.INITIAL) {
      if (!userId) {
        throw new Error('Missing user id.');
      }

      return services.memberService
        .getPosts({ userId, ...filter })
        .then(({ pagination, data }) => {
          return {
            user: { id: userId },
            posts: data,
            pagination,
          };
        });
    } else {
      // @TODO handle without braking UI
      throw new Error('Not ready for state transition.');
    }
  },
);

export const fetchMemberPosts = createAsyncThunk<
  MemberPostsData,
  FilterParams,
  ThunkConfig
>(
  'memberPosts/fetchMemberPosts',
  (filter = {}, { getState, extra: { services } }) => {
    const state = getState() as PartialState;
    const memberPosts = getMemberPosts(state);

    if (
      memberPosts.status === Status.PENDING ||
      memberPosts.status === Status.PENDING_AFTER_DELETE
    ) {
      const {
        user: { id },
      } = memberPosts;

      if (!id) {
        throw new Error('Missing user id.');
      }

      return services.memberService
        .getPosts({ userId: id, ...filter })
        .then(({ pagination, data }) => {
          return { pagination, posts: data };
        });
    } else {
      // @TODO handle without braking UI
      throw new Error('Not ready for state transition.');
    }
  },
);

export const deleteMemberPost = createAsyncThunk<
  Promise<unknown>,
  string,
  ThunkConfig
>(
  'memberPosts/deleteMemberPost',
  async (postId, { getState, extra: { services } }) => {
    const state = getState() as PartialState;
    const memberPosts = getMemberPosts(state);

    if (memberPosts.status === Status.PENDING) {
      return services.memberService.deletePost(postId);
    } else {
      // @TODO handle without braking UI
      throw new Error('Not ready for state transition.');
    }
  },
);

export default {
  fetchInitialMemberPosts,
  fetchMemberPosts,
  deleteMemberPost,
};
