import React, { FC } from 'react';
import { Dialog } from 'wix-ui-tpa';

interface ModalProps {
  handleClose: () => void;
}

const Modal: FC<ModalProps> = ({ handleClose, children }) => {
  return (
    <Dialog isOpen wiredToSiteColors={false} onClose={handleClose}>
      {children}
    </Dialog>
  );
};

export default Modal;
