import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { Status } from '../member-slice.models';
import { MemberPostsState } from './member-posts-slice.models';
import {
  fetchInitialMemberPosts,
  fetchMemberPosts,
  deleteMemberPost,
} from './member-posts-slice.actions';

export const MEMBER_POSTS_SLICE_NAME = 'memberPosts';

const initialState: MemberPostsState = {
  status: Status.INITIAL,
};

const buildFetchInitialMemberPosts = (
  builder: ActionReducerMapBuilder<MemberPostsState>,
) => {
  builder.addCase(
    fetchInitialMemberPosts.fulfilled,
    (state, { payload }): MemberPostsState => {
      if (state.status === Status.INITIAL) {
        return {
          status: Status.READY,
          user: payload.user,
          posts: payload.posts,
          pagination: payload.pagination,
        };
      }

      return state;
    },
  );
  builder.addCase(
    fetchInitialMemberPosts.rejected,
    (state): MemberPostsState => ({
      ...state,
      status: Status.ERROR,
    }),
  );
};

const buildFetchMemberPosts = (
  builder: ActionReducerMapBuilder<MemberPostsState>,
) => {
  builder.addCase(fetchMemberPosts.pending, (state): MemberPostsState => {
    if (state.status === Status.READY) {
      return {
        ...state,
        status: Status.PENDING,
      };
    }

    return state;
  });
  builder.addCase(
    fetchMemberPosts.fulfilled,
    (state, { payload }): MemberPostsState => {
      if (
        state.status === Status.PENDING ||
        state.status === Status.PENDING_AFTER_DELETE
      ) {
        return {
          ...state,
          status: Status.READY,
          posts: payload.posts,
          pagination: payload.pagination,
        };
      }

      return state;
    },
  );
  builder.addCase(
    fetchMemberPosts.rejected,
    (state): MemberPostsState => ({
      ...state,
      status: Status.ERROR,
    }),
  );
};

const buildDeleteMemberPost = (
  builder: ActionReducerMapBuilder<MemberPostsState>,
) => {
  builder.addCase(deleteMemberPost.pending, (state): MemberPostsState => {
    if (state.status === Status.READY) {
      return {
        ...state,
        status: Status.PENDING,
      };
    }

    return state;
  });
  builder.addCase(deleteMemberPost.fulfilled, (state): MemberPostsState => {
    if (state.status === Status.PENDING) {
      return {
        ...state,
        status: Status.PENDING_AFTER_DELETE,
      };
    }

    return state;
  });
  builder.addCase(
    deleteMemberPost.rejected,
    (state): MemberPostsState => ({
      ...state,
      status: Status.ERROR,
    }),
  );
};

const memberPostsSlice = createSlice<MemberPostsState, {}>({
  name: MEMBER_POSTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildFetchInitialMemberPosts(builder);
    buildFetchMemberPosts(builder);
    buildDeleteMemberPost(builder);
  },
});

export const reducer = memberPostsSlice.reducer;
