import React from 'react';
import { Preloader } from '@wix/wix-base-ui';

import { st, classes } from './SpinnerLoader.st.css';

const SpinnerLoader = () => {
  return (
    <div className={st(classes.loaderContainer)}>
      <Preloader />
    </div>
  );
};

export default SpinnerLoader;
